<template>
    <div class="wh100 comments_box">
       
       <transition name="fade">
            <template>
                <div class="dialog_box wh100">
                    <div class="comments_content_box boxBg">
                        <div class="title_box">
                            <p>
                                <span class="iconfont icon-title baseColor"></span>
                                <span>提意见</span>
                            </p>
                            <span class="iconfont icon-guanbi cp" @click="closeForm"></span>
                        </div>
                        <div class="textArea_box">
                            <el-input
                                type="textarea"
                                :rows="5"
                                placeholder="请输入您的诊断结果"
                                v-model="formInfo.content">
                                </el-input>
                        </div>
                        <div class="buts_box">
                            <el-button type="warning" plain @click="closeForm" size="mini" icon="el-icon-close">取消</el-button>
                            <el-button type="primary" plain @click="submitForm('ctlForm')" size="mini" icon="el-icon-check">提交</el-button>
                        </div>
                    </div>
                </div>
            </template>
       </transition>
    </div>
</template>

<script>
    export default {
        props:{
            devId: String
        },
        data(){
            return{
                formInfo:{
                  content: '',
                  imgId:'',
                },
            }
        },
        watch:{
           devId: {
              handler(val){
                  if(val) this.formInfo.imgId = val
              },
              immediate: true
           }
        },
        methods:{
           closeForm(){
               this.$set(this.formInfo,'content','')
               this.$emit('colse')
           },
           submitForm(){
               if(this.formInfo.content){
                    this.$api.AddIotInsectImgAdvice(this.formInfo).then(res => {
                        if(res){
                            this.$msg.success('提交成功');
                            this.closeForm()
                        }
                    })
               }else{
                   this.$msg.error('请输入意见内容');
               }
           }
        }
    }
</script>

<style lang="scss" scoped>
.comments_box{
     position: absolute;
     left: 0;
     top: 0;
   
   .dialog_box{
       position: absolute;
       left: 0;
       top: 0;
       z-index: 10;
       
      .comments_content_box{
          width: 400px;
          height: 250px;
          border-radius: 8px;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translateX(-50%) translateY(-50%);
          .title_box{
              width: 100%;
              height: 50px;
              box-sizing: border-box;
              padding: 0 10px;
              border-bottom: 2px solid #2C3E4A;
              display: flex;
              align-items: center;
              justify-content: space-between;
              & > p {
                   display: flex;
                  align-items: center;
                  & > span:first-child{
                      margin-right: 5px;
                      font-size: 22px;
                  }
              }
          }
          .textArea_box{
               width: 85%;
               margin:  18px auto;
              .el-textarea ::v-deep .el-textarea__inner{
                   background-color: #2C3E4A;    
                   border-color: transparent;   
                   color: #fff;           
              }
          }
          .buts_box{
              text-align: right;
              padding-right: 30px;
          }
      }  
   } 
   .fade-enter-active,
    .fade-leave-active {
    transition: opacity 0.5s;
    }
    .fade-enter,
    .fade-leave-to {
    opacity: 0;
    }
}
</style>