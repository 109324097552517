<template>
    <div class="wh100 comments_box">
       <!-- <div class="but_box active cp" @click="closePage= true">
           <span class="iconfont icon-bianji1"></span>
           <span></span>
       </div> -->
       <transition name="fade">
                <div class="dialog_box wh100">
                    <div class="comments_content_box boxBg">
                        <div class="title_box">
                            <p>
                                <span class="iconfont icon-title baseColor"></span>
                                <span>新增虫子</span>
                            </p>
                            <span class="iconfont icon-guanbi cp" @click="closeForm"></span>
                        </div>
                        <div class="textArea_box mt-20">
                      
                              <span class="lable">虫子名称：</span>
                               <el-select v-model="formInfo.libId" filterable style="width: 260px" @change="isError= false" placeholder="请选择虫子名称">
                                        <el-option
                                        v-for="item in insectOption"
                                        :key="item.id"
                                        :label="item.name"
                                        :value="item.id">
                                        </el-option>
                                </el-select>  
                        </div>
                        <p class="error_box" :style="showError">请选择虫子</p>
                        <div class="textArea_box">
                            <span class="lable">虫子数量：</span>
                            <el-input-number  class="row_number_int" v-model="formInfo.num" style="width: 260px" :min="1" :max="1000"></el-input-number> 
                        </div>
                      
                        <div class="buts_box">
                            <el-button type="warning" plain @click="closeForm" size="small" class="submit_but" icon="el-icon-close">取消</el-button>
                            <el-button type="primary" plain @click="submitForm('ctlForm')" class="submit_but" size="small" icon="el-icon-check">提交</el-button>
                        </div>
                    </div>
                </div>
       </transition>
    </div>
</template>

<script>
    export default {
        props:{
            imgId: String,
        },
        data(){
            return{
                formInfo:{
                  libId: '',
                  num:'',
                  imgId: ''
                },
                closePage: false,
                insectOption:[],
                isError: false
            }
        },
        watch:{
           devId: {
              handler(val){
                  if(val) this.formInfo.yfInsectCdnId = val
              },
              immediate: true
           }
        },
        created(){
           this.IotInsectLibChooseList()
        },
        computed:{
            showError(){
               return this.isError ? {visibility: 'visible' } : ''
            }   
        },
        methods:{
           closeForm(){
               this.$set(this.formInfo,'num','')
               this.$set(this.formInfo,'libId','')
               this.$emit('close')
            //    this.closePage= false
           },
                  // 新增虫子分析
          async AddIotInsectImgData(){
               const res = await this.$api.AddIotInsectImgData(this.formInfo)
                this.$msg.success('提交成功');
                this.$emit('success')
                this.closeForm()
          },
          //  获取虫库列表
          async IotInsectLibChooseList(){
             const res = await this.$api.IotInsectLibChooseList()
             this.insectOption = res
          },  
          submitForm(){
               if(this.formInfo.libId){
                  this.formInfo.imgId = this.imgId
                  this.AddIotInsectImgData() 
               }else this.isError= true
           }
        }
    }
</script>

<style lang="scss" scoped>
.comments_box{
   position: absolute;
    left: 0;
    top: 0;
   .dialog_box{
       position: absolute;
       left: 0;
       top: 0;
       z-index: 10;
       
      .comments_content_box{
          width: 400px;
          height: 260px;
          border-radius: 8px;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translateX(-50%) translateY(-50%);
          .title_box{
              width: 100%;
              height: 50px;
              box-sizing: border-box;
              padding: 0 10px;
              border-bottom: 2px solid #2C3E4A;
              display: flex;
              align-items: center;
              justify-content: space-between;
              & > p {
                   display: flex;
                  align-items: center;
                  & > span:first-child{
                      margin-right: 5px;
                      font-size: 22px;
                  }
              }
          }
          .textArea_box{
               width: 85%;
               margin:  0 auto;
               font-size: 14px;
              .lable{
                  display: inline-block;
                  width: 80px;
              }
          }
          .mt-20{
            margin-top: 20px;
          }
          .error_box{
             font-size: 12px;
             color: #e75353;
             padding: 5px 0;
             text-indent: 110px;
             visibility: hidden; // visible
          }
          
          .buts_box{
              text-align: right;
              padding-right: 30px;
              margin-top: 30px;
              .submit_but{
                  width: 125px;
              }
          }
      }  
   } 
   .fade-enter-active,
    .fade-leave-active {
    transition: opacity 0.5s;
    }
    .fade-enter,
    .fade-leave-to {
    opacity: 0;
    }
}
.row_number_int ::v-deep .el-input-number__decrease{
    border-left: none;
    border-right: 1px solid #DCDFE6;
}
.row_number_int ::v-deep .el-input-number__increase{
    border-bottom: none;
    border-left: 1px solid #DCDFE6;
}
</style>